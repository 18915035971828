import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  StyleRules,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  addConsultantComment,
  deleteConsultantComment,
  getConsultantComment,
} from '../../actions/consultant/consultantVerificationActions';
import { DeleteComment } from '../../components/Dialog/index';
import { showNotificationAction } from '../../reducers/notificationReducer';
import { dateFormatYearMonthDay } from '../../utils/converter';

const styles = (): StyleRules =>
  createStyles({
    commentContent: {
      margin: '0 auto',
      padding: '50px',
    },
    commentButton: {
      margin: '20px auto',
    },
    contentOver: {
      maxHeight: '500px',
      overflow: 'auto',
    },
    commentTime: {
      textAlign: 'right',
      // marginLeft: '70px',
      fontSize: '0.875rem',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  });

const ConsultantComment = ({ classes, ConsultantId }: Props) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState<ConsultantCommentInfo[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [commentId, setCommentId] = useState<number>();
  const selectComment = async (): Promise<void> => {
    if (ConsultantId) {
      const commentsData = await dispatch(getConsultantComment(ConsultantId));
      setComments(commentsData);
    }
  };

  const handleCommentSubmit = async (): Promise<void> => {
    if (!comment || comment.trim() == '') {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '请输入内容',
        })
      );
      return;
    }
    if (ConsultantId) {
      const commentsData = await dispatch(addConsultantComment(ConsultantId, comment.trim()));
      setComments([commentsData, ...comments]);
    }
  };
  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setComment(event.target.value);
  };
  const ConfirmDelete = async (): Promise<void> => {
    if (commentId) {
      const isDelete = await dispatch(deleteConsultantComment(commentId));
      if (isDelete) {
        setIsDialogOpen(false);
        selectComment();
      }
    }
  };
  const CancelDelete = async (): Promise<void> => {
    setIsDialogOpen(false);
  };
  useEffect(() => {
    ConsultantId ? selectComment() : '';
  }, []);
  return (
    <div className={classes.commentContent}>
      <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
        评论
      </Typography>
      <div className={classes.contentOver}>
        <List component="nav" aria-label="main mailbox folders">
          {comments.map((c, index) => (
            <div key={index}>
              <ListItem key={index}>
                <ListItemIcon>
                  <CommentIcon />
                </ListItemIcon>
                <ListItemText primary={c?.admin.user.email} secondary={c?.comment} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => {
                      setCommentId(c.id);
                      setIsDialogOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <p className={classes.commentTime}>
                评论时间：{dateFormatYearMonthDay(c.createTime)}
              </p>
            </div>
          ))}
        </List>
      </div>

      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        label="发布评论"
        value={comment}
        onChange={handleCommentChange}
      />
      <Button
        type="button"
        variant="contained"
        color="primary"
        className={classes.commentButton}
        onClick={handleCommentSubmit}
      >
        提交评论
      </Button>
      <DeleteComment
        isOpen={isDialogOpen}
        handleDelete={ConfirmDelete}
        handleDialogClose={CancelDelete}
      ></DeleteComment>
    </div>
  );
};

interface Props extends WithStyles<typeof styles> {
  className?: string;
  ConsultantId?: number;
}

export default withStyles(styles)(ConsultantComment);

import React from 'react';
import { useDispatch } from 'react-redux';
import { gql } from '@apollo/client';
import { alpha, createStyles, IconButton, Paper, StyleRules, Theme } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { printConsultantDetail } from '../../../actions/admin/adminActions';
import AdminLayout from '../../../layout/AdminLayout';
import { loadingEndAction, loadingStartAction } from '../../../reducers/loadingReducer';
import { showNotificationAction } from '../../../reducers/notificationReducer';
import { mutate } from '../../../utils/graphql';
import ConsultantComment from '../../../views/admin/ConsultantComment';
import ConsultantDetailView from '../../../views/admin/ConsultantDetailView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      width: 1128,
      margin: 'auto',
      height: theme.spacing(3),
    },
    img: {
      width: 40,
      marginBottom: 0,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    usernameDisplay: {
      paddingLeft: '4px',
      fontSize: '0.7em',
    },
    menuText: {
      fontSize: '1.2em',
    },
    noAdmin: {
      height: 1000,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    noAdminItem: {
      margin: '1%',
    },
    CommentMargin: {
      position: 'relative',
      margin: '0 auto',
      maxWidth: '70%',
      textAlign: 'center',
    },
    textRight: {
      position: 'fixed',
      right: '100px',
      bottom: '100px',
    },
    btnMargin: {
      margin: '20px 10%',
    },
  });

const UPDATE_CONSULTANT_APPROVED = gql`
  mutation MUTATION($verificationId: Long!) {
    adminApproveConsultantVerification(verificationId: $verificationId) {
      id
      status
      consultant {
        id
      }
      createTime
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Detail = ({ location, classes }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const consultantId = parseInt(location.hash.replace('#', ''));

  const downloadPdf = async () => {
    dispatch(loadingStartAction());
    dispatch(printConsultantDetail(consultantId));
  };

  const approved = async () => {
    const verificationId = Number(consultantId);
    dispatch(loadingStartAction());
    const { data, loading, error } = await mutate(UPDATE_CONSULTANT_APPROVED, { verificationId });

    if (error) {
      dispatch(showNotificationAction({ severity: 'error', message: '审核失败' }));
    }
    if (data?.adminApproveConsultantVerification) {
      dispatch(showNotificationAction({ severity: 'success', message: '审核完成' }));
    }

    if (loading) {
      dispatch(loadingStartAction());
    } else {
      dispatch(loadingEndAction());
    }
  };

  return (
    <AdminLayout>
      {/* Header */}
      <div className={classes.CommentMargin}>
        <ConsultantDetailView inputConsultantId={consultantId} />
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.btnMargin}
          onClick={downloadPdf}
        >
          下载pdf
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.btnMargin}
          onClick={approved}
        >
          通过审核
        </Button>
        <Paper>
          <ConsultantComment ConsultantId={consultantId}></ConsultantComment>
        </Paper>
      </div>
      <IconButton
        className={classes.textRight}
        aria-label="TOP"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        <KeyboardArrowUpIcon />
      </IconButton>
    </AdminLayout>
  );
};

export interface Props extends WithStyles<typeof styles> {
  title?: string;
  location: Location;
}

export default withStyles(styles)(Detail);
